import React from 'react';
import Box from '@mui/material/Box';
import CopywriteLayout from '../components/layouts/CopywriteLayout';
import Button from '@mui/material/Button';

import HouseIcon from '../assets/icons/banking/house.png';
import CarIcon from '../assets/icons/banking/car.png';
import HandIcon from '../assets/icons/banking/hand.png';
import BulbIcon from '../assets/icons/banking/bulb.png';
import BriefcaseIcon from '../assets/icons/banking/briefcase.png';
import PlantIcon from '../assets/icons/banking/plant.png';
import CardIcon from '../assets/icons/banking/card.png';

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CalculatorModalButton from '../components/modals/CalculatorModal';

const SectionTitle = ({ icon, children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    <Box
      style={{
        display: 'flex',
        marginRight: '10px',
      }}
    >
      <img src={icon} alt="" width={40} height={40} />
    </Box>
    {children}
  </div>
);

export default function LoansPage() {
  return (
    <CopywriteLayout>
      <CalculatorModalButton />
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>Loans & Mortgages at Hi-Land Credit Union</h1>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Button
            href="/applications"
            variant="contained"
            color="secondary"
            sx={{ paddingX: 4 }}
          >
            Apply now
          </Button>
          <SwapHorizIcon
            fontSize="large"
            sx={{ marginX: 3, marginY: { xs: 1, sm: 0 } }}
          />
          <Button href="/rates" color="primary" variant="contained">
            Explore interest rates
          </Button>
        </Box>
      </div>
      <br />
      <SectionTitle icon={HouseIcon} children={<h2 id="home">Home</h2>} />
      <br />
      <body>
        At Hi-Land Credit Union, we are dedicated to making your dream home a
        reality. What sets our home loans apart is our commitment to providing
        some of the lowest rates in the Salt Lake Valley. As a not for profit
        organization focused on our members' needs, we prioritize affordability
        and convenience in homeownership.
      </body>
      <br />
      <body>
        One distinctive feature of our home loans is the absence of origination
        fees, saving you thousands during the homebuying process. Our in-house
        approach ensures that you will always work directly with us, eliminating
        third-party involvement and allowing for a super quick and seamless
        turnaround. Whether you are a first-time homebuyer or looking to
        refinance, we tailor our loan terms to fit your unique financial
        situation.
      </body>
      <br />
      <body>
        Trust Hi-Land Credit Union to guide you through the process with
        personalized service and a commitment to efficiency. Your dream home is
        within reach with our home loans, designed to make the journey to
        homeownership smooth and affordable.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <body>
          <Button href="/rates" color="primary">
            Explore interest rates
          </Button>
        </body>
      </div>
      <br />
      <SectionTitle icon={CarIcon} children={<h2 id="auto">Auto</h2>} />
      <br />
      <body>
        Rev up your dreams of a new or used vehicle with Hi-Land Credit Union's
        exceptional auto loans. We take pride in offering some of the lowest
        rates in the Salt Lake Valley, reflecting our commitment to providing
        affordable financing options for our members. As a not for profit
        organization, we prioritize your needs over profits, making your auto
        ownership experience both enjoyable and cost-effective.
      </body>
      <br />
      <body>
        Our auto loans start at a competitive 5.99 percent, ensuring that you
        not only drive away in style but also save thousands over the life of
        your loan. Whether you're eyeing a new car, a reliable used vehicle, or
        even boats, RVs, and ATVs, we've got you covered. At Hi-Land, we
        understand that everyone's financial situation is unique, and that's why
        we offer flexible terms and products to suit your personal needs.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <body>
          <Button href="/applications" color="secondary">
            Apply now
          </Button>{' '}
          or{' '}
          <Button href="/rates" color="primary">
            Explore interest rates
          </Button>
        </body>
      </div>
      <br />
      <SectionTitle
        icon={HandIcon}
        children={<h2 id="personal">Personal</h2>}
      />
      <br />
      <body>
        Navigate life's unexpected turns with ease through Hi-Land Credit
        Union's flexible and reliable personal loans. Whether you're facing
        emergencies or just need some extra cash, our personal loans are
        designed to be a versatile financial solution. As a not for profit
        organization, we prioritize your well-being, offering a lending
        experience that is both accommodating and cost-effective.
      </body>
      <br />
      <body>
        At Hi-Land, we understand that life can throw curveballs, and sometimes
        you need a financial cushion to navigate them. Our unsecured personal
        loans provide a flexible way to tackle unforeseen expenses. With no
        annual fees, our personal loans offer a hassle-free solution for those
        moments when you need a bit more financial flexibility.
      </body>
      <br />
      <body>
        Trust Hi-Land Credit Union for your personal loan needs, and experience
        the convenience of quick and efficient turnaround times. Whether you're
        dealing with unexpected expenses or seizing an opportunity, our personal
        loans provide the financial support you need with a touch of
        flexibility. Choose us as your financial partner, and let us help you
        navigate life's financial twists and turns with confidence.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <body>
          <Button href="/rates" color="primary">
            Explore interest rates
          </Button>
        </body>
      </div>
      <br />
      <SectionTitle
        icon={BulbIcon}
        children={<h2 id="equity">Home Equity Lines of Credit</h2>}
      />
      <br />
      <body>
        Unlock the potential of your home's equity with Hi-Land Credit Union's
        Home Equity Lines of Credit (HELOC). Designed to empower you with
        financial flexibility, a HELOC allows you to tap into your home's equity
        for various needs. As a not for profit organization committed to our
        members' financial well-being, we offer competitive terms and products
        to ensure that your borrowing experience is both advantageous and
        tailored to your individual requirements.
      </body>
      <br />
      <body>
        With Hi-Land's HELOC, you gain access to funds for home improvements,
        education expenses, debt consolidation, or any other financial goals you
        may have. What sets us apart is our commitment to transparency and
        member-centric practices. Offering you a cost-effective solution to
        leverage the value of your home. We provide no origination fees, you pay
        for the appraisal, flood, and title insurance. The credit union covers
        title fees. However, if you close the HELOC within three years, there's
        a $300 prepayment penalty. Our in-house processing ensures a seamless
        experience, with direct communication and no intermediaries involved.
      </body>
      <br />
      <body>
        Choose Hi-Land Credit Union for your Home Equity Line of Credit, and
        experience the ease of working with a partner dedicated to your
        financial success. Whether you're embarking on a home project or
        pursuing other financial goals, our HELOC is a smart and flexible
        solution. Transform your home's equity into opportunities with Hi-Land
        Credit Union.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <body>
          <Button href="/rates" color="primary">
            Explore interest rates
          </Button>
        </body>
      </div>
      <br />
      <SectionTitle
        icon={BriefcaseIcon}
        children={<h2 id="overdraft">Overdraft Lines of Credit</h2>}
      />
      <br />
      <body>
        Ensure peace of mind with Hi-Land Credit Union's Overdraft Lines of
        Credit, a valuable resource for managing unexpected expenses and
        avoiding unnecessary overdraft fees. As a not for profit organization
        deeply committed to our members' financial well-being, we understand
        that life can be unpredictable, and our Overdraft Lines of Credit
        provide a safety net for those times when you need a little extra
        buffer.
      </body>
      <br />
      <body>
        Hi-Land's Overdraft Lines of Credit offer a convenient solution to cover
        short-term financial gaps, providing a cushion for minor emergencies or
        waiting for that next paycheck. With our commitment to transparency and
        member-centric practices, we ensure that you have the support you need
        without hidden fees or excessive charges.
      </body>
      <br />
      <body>
        Opt for Hi-Land Credit Union for your Overdraft Line of Credit, and
        experience the comfort of having an extra layer of financial security.
        Our straightforward approach, coupled with quick and efficient
        processing, ensures that you can navigate unexpected financial
        challenges with ease. Choose us as your financial partner, and let our
        Overdraft Lines of Credit offer you the flexibility and assurance you
        deserve.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <body>
          <Button href="/rates" color="primary">
            Explore interest rates
          </Button>
        </body>
      </div>
      <br />
      <SectionTitle
        icon={PlantIcon}
        children={<h2 id="secondmtg">Second Mortgages</h2>}
      />
      <br />
      <body>
        Unlock additional financial possibilities with Hi-Land Credit Union's
        Second Mortgages, providing a strategic way to leverage the equity in
        your home. As a not for profit organization deeply rooted in our
        commitment to members' financial well-being, we offer Second Mortgages
        as a flexible solution tailored to meet your unique needs.
      </body>
      <br />
      <body>
        Hi-Land's Second Mortgages empower you to access funds for significant
        expenses, whether it's home improvements, education costs, or debt
        consolidation. What sets us apart is our dedication to transparency and
        member-centric practices. We strive to make the borrowing process
        straightforward by providing clear terms and avoiding unnecessary fees.
        With in-house processing, you can count on direct communication and a
        seamless experience throughout the loan process.
      </body>
      <br />
      <body>
        Choose Hi-Land Credit Union for your Second Mortgage needs, and
        experience the advantages of working with a financial partner that
        prioritizes your goals. Our commitment to your financial success,
        coupled with competitive terms, ensures that your Second Mortgage
        journey is not only efficient but also customized to your financial
        aspirations. Transform your home's equity into opportunities with
        Hi-Land Credit Union's Second Mortgages.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <body>
          <Button href="/rates" color="primary">
            Explore interest rates
          </Button>
        </body>
      </div>
      <br />
      <SectionTitle
        icon={CardIcon}
        children={<h2 id="creditcards">Credit Cards</h2>}
      />
      <br />
      <body>
        Whether you're indulging in shopping, dining, or travel, relish the
        convenience and security offered by the world's top payment card—VISA.
        With a VISA from Hi-Land Credit Union, you enjoy a fantastic low rate,
        starting at 10.9% APR, coupled with rewards, no annual fees, and a
        generous 30-day payment grace period. Earn rewards on every purchase,
        joining the millions of global shoppers who have made VISA their
        preferred choice. Get your VISA card today and experience the best in
        payment convenience! Please review our{' '}
        <a
          href="/static/documents/credit_card_agreement_and_disclosures.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Credit Card Agreement and Disclosures
        </a>{' '}
        before applying.
      </body>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button href="/applications" color="secondary">
          Apply now
        </Button>
      </div>
      <br />
    </CopywriteLayout>
  );
}
