import React, { useState } from 'react';
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import CalculatorLoanPayments from './CalculatorLoanPayments';
import CalculatorLoanBudget from './CalculatorLoanBudget';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CalculatorWidget() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container disableGutters>
      <Box display="flex" justifyContent="center">
        <Typography variant="h6">Loan Calculator</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        sx={{
          paddingX: { xs: '2rem' },
          paddingTop: { xs: '2rem' },
          paddingBottom: { xs: '3rem' },
        }}
      >
        {/* Start Tabs */}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="calculator tabs"
            >
              <Tab label="Monthly Payment" {...a11yProps(1)} />
              <Tab label="Total Loan Budget" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <CalculatorLoanPayments />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CalculatorLoanBudget />
          </CustomTabPanel>
        </Box>
        {/* End Tabs */}
      </Box>
    </Container>
  );
}

// END
