import { useState, useEffect } from 'react';
import {
  Box,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const termTypes = {
  // period in months
  years: 12,
  months: 1,
};

const formatString = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const stripFormatString = (value) => {
  return value.toString().replace(/[$,]/g, '');
};

export default function App() {
  // inputs
  const [loanAmount, setLoanAmount] = useState('12,000');
  const [interestRate, setInterestRate] = useState('12.125');
  const [loanTerm, setLoanTerm] = useState('3');
  const [termType, setTermType] = useState('years');

  // outputs
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [totalPayment, setTotalPayment] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);

  // errors
  const [loanAmountError, setLoanAmountError] = useState('');
  const [interestRateError, setInterestRateError] = useState('');
  const [loanTermError, setLoanTermError] = useState('');

  const onLoanAmountChange = (e) => {
    const newValue = stripFormatString(e.target.value);
    if (newValue.match(/^\d+(\.\d{0,2})?$/) || newValue === '') {
      setLoanAmountError(null);
      setLoanAmount(formatString(newValue));
    } else if (newValue.match(/^\d+(\.\d{3,})?$/)) {
      setLoanAmountError(null);
    } else {
      setLoanAmountError('Please enter a valid amount');
    }
  };

  const onInterestRateChange = (e) => {
    const newValue = e.target.value;
    if (newValue.match(/^\d+(\.\d{0,3})?$/) || newValue === '') {
      setInterestRateError(null);
      setInterestRate(newValue);
    } else if (newValue.match(/^\d+(\.\d{4,})?$/)) {
      setInterestRateError(null);
    } else {
      setInterestRateError('Please enter a valid rate');
    }
  };

  const onLoanTermChange = (e) => {
    const newValue = e.target.value;
    if (newValue.match(/^\d+$/) || newValue === '') {
      setLoanTermError(null);
      setLoanTerm(newValue);
    } else {
      setLoanTermError('Please enter a valid term');
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [loanAmount, interestRate, loanTerm, termType]);

  function handleSubmit() {
    if (loanAmount === '' || interestRate === '' || loanTerm === '') {
      console.log('Invalid input values');
      return;
    }
    // Get and convert input values.
    const monthlyInterestRate = parseFloat(interestRate) / 100 / 12;
    const loanTermInMonths =
      parseFloat(loanTerm) * parseFloat(termTypes[termType]);

    // Calculate monthly mortgage payment.
    const monthlyPaymentAmount =
      (parseFloat(stripFormatString(loanAmount)) * monthlyInterestRate) /
      (1 - 1 / Math.pow(1 + monthlyInterestRate, loanTermInMonths));

    // Calculate total payment amount.
    const totalPayment = monthlyPaymentAmount * loanTermInMonths;

    // Display monthly payment amount.
    // setMonthlyPayment(currencyFormatter.format(monthlyPaymentAmount));
    setMonthlyPayment(String(monthlyPaymentAmount));
    // Display total payment amount.
    setTotalPayment(String(totalPayment));
    // Display total interest amount.
    setTotalInterest(String(totalPayment - stripFormatString(loanAmount)));
  }

  return (
    <Box className="mortgage-calculator">
      <Box
        display="flex"
        flexDirection="column"
        className="mortgage-calculator"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        paddingY="1rem"
      >
        <Box marginY="1rem">
          <Typography fontSize="1.4rem">What is your loan amount?</Typography>
        </Box>

        <Box marginBottom="1rem">
          <TextField
            name="loan-amount"
            variant="standard"
            min="1"
            value={String(loanAmount)}
            onChange={onLoanAmountChange}
            inputProps={{
              style: {
                textAlign: 'center',
                paddingRight: '1rem',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            error={!!loanAmountError}
            helperText={loanAmountError}
            sx={{
              '& .MuiFormHelperText-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          />
        </Box>
        <Box display="flex" marginY="1rem">
          <TextField
            label="Interest"
            name="interest-rate"
            value={String(interestRate)}
            onChange={onInterestRateChange}
            inputProps={{
              min: 0.01,
              style: {
                minWidth: '3rem',
                textAlign: 'right',
                paddingLeft: '5.3rem',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={(theme) => ({
                    marginLeft: '0.5rem',
                    [theme.breakpoints.only('xs')]: {
                      marginRight: '1.5rem',
                    },
                  })}
                >
                  %
                </InputAdornment>
              ),
            }}
            error={!!interestRateError}
            helperText={interestRateError}
            sx={{
              '& .MuiFormHelperText-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          />
          <TextField
            label="Loan term"
            name="loan-term"
            value={String(loanTerm)}
            onChange={onLoanTermChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ minWidth: '5.5rem' }}
                  >
                    <Select
                      id="demo-simple-select"
                      value={termType}
                      onChange={(e) => setTermType(e.target.value)}
                    >
                      <MenuItem value={'years'}>Years</MenuItem>
                      <MenuItem value={'months'}>Months</MenuItem>
                    </Select>
                  </FormControl>
                </InputAdornment>
              ),
            }}
            sx={{
              marginLeft: '0.5rem',
              '& .MuiFormHelperText-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
            error={!!loanTermError}
            helperText={loanTermError}
          />
        </Box>
        <Box
          sx={{
            borderRadius: 1,
            padding: 2,
            bgcolor: 'rgb(200, 200, 200, 0.2)',
            width: '100%',
          }}
        >
          <Box>Your estimated monthly payment will be</Box>
          <Box>
            <h2>
              {isNaN(monthlyPayment) || monthlyPayment === 'Infinity'
                ? '$ ──'
                : currencyFormatter.format(monthlyPayment)}
            </h2>
          </Box>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={(Number(totalInterest) / Number(totalPayment)) * 100}
              sx={{
                borderRadius: 8,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: 'rgb(255, 204, 0, 1.0)',
                },
                backgroundColor: 'rgb(0, 88, 157, 1.0)',
              }}
            />
          </Box>
          <Box id="output-fields" marginTop="1em">
            <Box
              id="total-interest"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CircleIcon
                  style={{
                    color: 'rgb(255, 204, 0, 1.0)',
                    fontSize: 12,
                    marginRight: '0.3em',
                  }}
                />{' '}
                Total interest
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isNaN(totalInterest) || totalInterest === 'Infinity'
                  ? '$ ──'
                  : currencyFormatter.format(totalInterest)}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CircleIcon
                  style={{
                    color: 'rgb(0, 88, 157, 1.0)',
                    fontSize: 12,
                    marginRight: '0.3em',
                  }}
                />{' '}
                Total principal
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isNaN(stripFormatString(loanAmount)) ||
                stripFormatString(loanAmount) === 'Infinity'
                  ? '$ ──'
                  : currencyFormatter.format(stripFormatString(loanAmount))}
              </Box>
            </Box>
            <Box
              id="total-payments"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.15em',
                }}
              >
                <strong>Total loan payments</strong>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <strong>
                  {isNaN(totalPayment) || totalPayment === 'Infinity'
                    ? '$ ──'
                    : currencyFormatter.format(totalPayment)}
                </strong>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Disclaimer */}
        <Box
          sx={{
            marginLeft: '1rem',
            marginTop: '0.5rem',
            fontSize: '0.8rem',
            color: 'grey',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Tooltip
            title="Calculator values are for informational purposes only. Please contact a banker for details."
            placement="bottom-start"
          >
            <Box>Disclaimer</Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}
