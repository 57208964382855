import { useState, useEffect } from 'react';
import {
  Box,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
} from '@mui/material';
import { Circle as CircleIcon } from '@mui/icons-material';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const termTypes = {
  // period in months
  years: 12,
  months: 1,
};

const formatString = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const stripFormatString = (value) => {
  return value.toString().replace(/[$,]/g, '');
};

export default function App() {
  // inputs
  const [monthlyPayment, setMonthlyPayment] = useState('268');
  const [interestRate, setInterestRate] = useState('12.125');
  const [loanTerm, setLoanTerm] = useState('3');
  const [termType, setTermType] = useState('years');

  // outputs
  const [maxLoanAmount, setMaxLoanAmount] = useState(null);
  const [totalPayment, setTotalPayment] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);

  // errors
  const [monthlyPaymentError, setMonthlyPaymentError] = useState('');
  const [interestRateError, setInterestRateError] = useState('');
  const [loanTermError, setLoanTermError] = useState('');

  const onMonthlyPaymentChange = (e) => {
    const newValue = stripFormatString(e.target.value);
    if (newValue.match(/^\d+(\.\d{0,2})?$/) || newValue === '') {
      setMonthlyPaymentError(null);
      setMonthlyPayment(formatString(newValue));
    } else if (newValue.match(/^\d+(\.\d{3,})?$/)) {
      setMonthlyPaymentError(null);
    } else {
      setMonthlyPaymentError('Please enter a valid amount');
    }
  };

  const onInterestRateChange = (e) => {
    const newValue = e.target.value;
    if (newValue.match(/^\d+(\.\d{0,3})?$/) || newValue === '') {
      setInterestRateError(null);
      setInterestRate(newValue);
    } else if (newValue.match(/^\d+(\.\d{4,})?$/)) {
      setInterestRateError(null);
    } else {
      setInterestRateError('Please enter a valid rate');
    }
  };

  const onLoanTermChange = (e) => {
    const newValue = e.target.value;
    if (newValue.match(/^\d+$/) || newValue === '') {
      setLoanTermError(null);
      setLoanTerm(newValue);
    } else {
      setLoanTermError('Please enter a valid term');
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [monthlyPayment, interestRate, loanTerm, termType]);

  function handleSubmit() {
    if (monthlyPayment === '' || interestRate === '' || loanTerm === '') {
      console.log('Invalid input values');
      return;
    }
    // Get and convert input values.
    const monthlyInterestRate = parseFloat(interestRate) / 100 / 12;
    const loanTermInMonths =
      parseFloat(loanTerm) * parseFloat(termTypes[termType]);

    // Calculate monthly mortgage payment.
    const monthlyPaymentAmount = parseFloat(stripFormatString(monthlyPayment));
    //   (parseFloat(maxLoanAmount) * monthlyInterestRate) /
    //   (1 - 1 / Math.pow(1 + monthlyInterestRate, loanTermInMonths));

    // Calculate total payment amount.
    const loanAmount =
      (monthlyPaymentAmount / monthlyInterestRate) *
      (1 - 1 / Math.pow(1 + monthlyInterestRate, loanTermInMonths));

    const totalPayment = monthlyPaymentAmount * loanTermInMonths;

    // Display monthly payment amount.
    setMaxLoanAmount(String(loanAmount));
    // Display total payment amount.
    setTotalPayment(String(totalPayment));
    // Display total interest amount.
    setTotalInterest(String(totalPayment - loanAmount));
  }

  return (
    <Box className="mortgage-calculator">
      <Box
        display="flex"
        flexDirection="column"
        className="mortgage-calculator"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        paddingY="1rem"
      >
        <Box marginY="1rem">
          <Typography fontSize="1.4rem">
            What is a monthly payment you can afford?
          </Typography>
        </Box>

        <Box marginBottom="1rem">
          <TextField
            name="loan-amount"
            variant="standard"
            min="1"
            value={String(monthlyPayment)}
            onChange={onMonthlyPaymentChange}
            inputProps={{
              style: { textAlign: 'center', paddingRight: '1rem' },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            error={!!monthlyPaymentError}
            helperText={monthlyPaymentError}
            sx={{
              '& .MuiFormHelperText-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          />
        </Box>
        <Box display="flex" marginY="1rem">
          <TextField
            label="Interest"
            name="interest-rate"
            value={String(interestRate)}
            onChange={onInterestRateChange}
            inputProps={{
              min: 0.01,
              style: {
                minWidth: '3rem',
                textAlign: 'right',
                paddingLeft: '5.3rem',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={(theme) => ({
                    marginLeft: '0.5rem',
                    [theme.breakpoints.only('xs')]: {
                      marginRight: '1.5rem',
                    },
                  })}
                >
                  %
                </InputAdornment>
              ),
            }}
            error={!!interestRateError}
            helperText={interestRateError}
            sx={{
              '& .MuiFormHelperText-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          />
          <TextField
            label="Loan term"
            name="loan-term"
            value={String(loanTerm)}
            onChange={onLoanTermChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ minWidth: '5.5rem' }}
                  >
                    <Select
                      id="demo-simple-select"
                      value={termType}
                      onChange={(e) => setTermType(e.target.value)}
                    >
                      <MenuItem value={'years'}>Years</MenuItem>
                      <MenuItem value={'months'}>Months</MenuItem>
                    </Select>
                  </FormControl>
                </InputAdornment>
              ),
            }}
            sx={{
              marginLeft: '0.5rem',
              '& .MuiFormHelperText-root': {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
            error={!!loanTermError}
            helperText={loanTermError}
          />
        </Box>
        <Box
          sx={{
            borderRadius: 1,
            padding: 2,
            bgcolor: 'rgb(200, 200, 200, 0.2)',
            width: '100%',
          }}
        >
          <Box>You can afford a loan up to</Box>
          <Box>
            <h2>
              {isNaN(maxLoanAmount) || maxLoanAmount === 'Infinity'
                ? '$ ──'
                : currencyFormatter.format(maxLoanAmount)}
            </h2>
          </Box>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={(Number(totalInterest) / Number(totalPayment)) * 100}
              sx={{
                borderRadius: 8,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: 'rgb(255, 204, 0, 1.0)',
                },
                backgroundColor: 'rgb(0, 88, 157, 1.0)',
              }}
            />
          </Box>
          <Box id="output-fields" marginTop="1em">
            <Box
              id="total-interest"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CircleIcon
                  style={{
                    color: 'rgb(255, 204, 0, 1.0)',
                    fontSize: 12,
                    marginRight: '0.3em',
                  }}
                />{' '}
                Total interest
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isNaN(totalInterest) || totalInterest === 'Infinity'
                  ? '$ ──'
                  : currencyFormatter.format(totalInterest)}
              </Box>
            </Box>
            <Box
              id="total-princial"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CircleIcon
                  style={{
                    color: 'rgb(0, 88, 157, 1.0)',
                    fontSize: 12,
                    marginRight: '0.3em',
                  }}
                />{' '}
                Total principal
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isNaN(maxLoanAmount) || maxLoanAmount === 'Infinity'
                  ? '$ ──'
                  : currencyFormatter.format(maxLoanAmount)}
              </Box>
            </Box>
            <Box
              id="total-payments"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.15em',
                }}
              >
                <strong>Total loan payments</strong>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <strong>
                  {isNaN(totalPayment) || totalPayment === 'Infinity'
                    ? '$ ──'
                    : currencyFormatter.format(totalPayment)}
                </strong>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Disclaimer */}
        <Box
          sx={{
            marginLeft: '1rem',
            marginTop: '0.5rem',
            fontSize: '0.8rem',
            color: 'grey',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Tooltip
            title="Calculator values are for informational purposes only. Please contact a banker for details."
            placement="bottom-start"
          >
            <Box>Disclaimer</Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}
