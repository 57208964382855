import React from 'react';
import Box from '@mui/material/Box';
import CopywriteLayout from '../components/layouts/CopywriteLayout';

import ShieldIcon from '../assets/icons/banking/shield.png';
import PieChartIcon from '../assets/icons/banking/pie-chart.png';
import CalculatorIcon from '../assets/icons/banking/calculator.png';
import EnvelopeIcon from '../assets/icons/banking/envelope.png';
import BankIcon from '../assets/icons/banking/bank.png';
import EyeMagnifierIcon from '../assets/icons/banking/eye-magnifier.png';
import CalculatorModalButton from '../components/modals/CalculatorModal';

const SectionTitle = ({ icon, children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    <Box
      style={{
        display: 'flex',
        marginRight: '10px',
      }}
    >
      <img src={icon} alt="" width={40} height={40} />
    </Box>
    {children}
  </div>
);

export default function ResourcesPage() {
  return (
    <CopywriteLayout>
      <CalculatorModalButton />
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1>Resources & Education at Hi-Land Credit Union</h1>
      </div>
      <br />
      <SectionTitle
        icon={ShieldIcon}
        children={<h2 id="security">Security</h2>}
      />
      <br />
      <body>
        Online scams are unfortunately becoming more prevalent today.
        Cybercriminals employ various tactics, such as breaking into mailboxes,
        sending viruses via email, and creating deceptive websites. Guard
        yourself against falling victim to these scams. The most effective
        defense is knowledge. Stay informed about the latest fraudulent methods
        and safeguard your personal information. Ensure that your personal
        computer is equipped with up-to-date antivirus and spyware programs, and
        be vigilant about phishing attempts.
      </body>
      <br />
      <body>
        Explore the Hi-Land Credit Union Security Center for valuable
        information on protecting yourself from identity theft, mail and email
        scams, computer viruses, and other forms of fraud. Take a moment to
        peruse these resources, where you can stay abreast of the newest threats
        and learn effective strategies to counter them.
      </body>
      <br />
      <body>
        Another great way to protect yourself from identity theft is to make
        sure your contact information is up-to-date with us. If you move or
        change your phone number, please let us know so we can update your
        account and contact you if we suspect fraudulent activity.
      </body>
      <br />
      <body>
        <a href="/security">Click here to visit the Security Center</a>
      </body>
      <br />
      <SectionTitle
        icon={PieChartIcon}
        children={<h2 id="edu">Education</h2>}
      />
      <br />
      <body>
        Acquiring knowledge is an ongoing journey throughout life. However,
        grasping the intricacies of finances can be particularly challenging,
        especially in a constantly evolving economy. Recognizing this, Hi-Land
        Credit Union has established our Education Center.
      </body>
      <br />
      <body>
        Within these pages, you'll discover current articles and valuable advice
        covering a range of personal finance topics. Our goal is to offer
        resources that assist you in navigating the complexities of today's most
        significant financial matters.
      </body>
      <br />
      <body>
        <a href="/education">Click here to visit the Education Center</a>
      </body>
      <br />
      <SectionTitle
        icon={CalculatorIcon}
        children={<h2 id="tools">Financial Tools</h2>}
      />
      <br />
      <body>
        Discover the financial planning utilities offered by Hi-Land Credit
        Union, featuring our Savings, Loan Payment, and Mortgage Calculators.
        These intuitive tools are crafted to provide you with valuable insights,
        aiding in well-informed financial decision-making.
      </body>
      <br />
      <body>
        Delve into our Savings Calculator to project the potential growth of
        your savings by entering your initial deposit, interest rate, and
        savings duration. For managing loans, utilize our Loan Payment
        Calculator to gain clarity on monthly payments by inputting your loan
        amount, interest rate, and term. Additionally, if you're contemplating a
        mortgage, our Mortgage Calculator allows you to analyze different
        scenarios by entering your loan amount, interest rate, and term. These
        calculators reflect Hi-Land Credit Union's dedication to equipping you
        with resources that enhance your ability to plan and navigate your
        financial journey effectively.
      </body>
      <br />
      <body>
        <a href="/calculator">Click here to use the Loan Payment Calculator</a>
      </body>
      <br />
      <SectionTitle
        icon={EnvelopeIcon}
        children={<h2 id="apps">Applications</h2>}
      />
      <br />
      <body>
        At Hi-Land Credit Union, we pride ourselves on providing a rapid and
        efficient application process, ensuring quick turnaround times for your
        financial needs. Whether you're applying for a loan or a credit card,
        our streamlined procedures prioritize prompt responses. Your time is
        valuable to us, and we aim to make the application experience seamless
        and convenient. Ready to take the next step?{' '}
        <a href="/applications">Click here</a> to start an application now and
        experience the swift service of Hi-Land Credit Union.
      </body>

      <br />
      <SectionTitle icon={BankIcon} children={<h2 id="about">About Us</h2>} />
      <br />
      <body>
        Established in 1956, Hi-Land Credit Union originated as the credit union
        for Hi-Land Dairy, forging lasting bonds with its employees. Over the
        years, our commitment to service has expanded, and we are delighted to
        now serve members throughout the Salt Lake Valley. Our inclusive
        membership field encompasses those living or working in Salt Lake
        County, as well as the family members of current credit union members.
        Operating as a one-branch credit union, we deliberately choose this
        approach to ensure that each member receives personalized dedication and
        full attention to their financial needs. This commitment allows us to
        provide a level of service that recognizes the unique financial goals
        and aspirations of every individual.
      </body>
      <br />
      <body>
        At Hi-Land Credit Union, our focus on individualized attention sets us
        apart. By maintaining a single branch, we can deliver the personalized
        service and support that each member deserves. We believe in fostering a
        sense of community within our membership, and our history rooted in
        serving the Hi-Land Dairy community underscores our dedication to
        building lasting relationships and providing quality financial services
        to our members across the Salt Lake Valley.
      </body>
      <br />
      <SectionTitle
        icon={EyeMagnifierIcon}
        children={<h2 id="links">Helpful Links</h2>}
      />
      <br />
      <body>
        <li>
          <a href="/contact">Get in Touch</a>
        </li>
        <li>
          <a href="/rates">Check Current Interest Rates</a>
        </li>
        <li>
          <a href="/applications">Apply for a Loan</a>
        </li>
        <li>
          <a href="/education">Visit the Education Center</a>
        </li>
        <li>
          <a href="/security">Visit the Security Center</a>
        </li>
      </body>
      <br />
    </CopywriteLayout>
  );
}
