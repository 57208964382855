import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CalculatorWidget from '../widgets/CalculatorWidget';
import { IconButton, Tooltip } from '@mui/material';
import CalculatorIcon from '../../assets/icons/banking/calculator.png';
import CancelIcon from '@mui/icons-material/Cancel';
import Fab from '@mui/material/Fab';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //   width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
};

export function CalculatorModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Tooltip title="Loan Calculator">
        <IconButton onClick={handleOpen}>
          <img
            src={CalculatorIcon}
            alt="Calculator"
            width={40}
            height={40}
            style={{ filter: 'invert(100%)' }}
          />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            justifyContent="flex-end"
            marginTop={{ xs: '1rem', sm: '0rem' }}
          >
            <IconButton onClick={handleClose}>
              <CancelIcon fontSize="large" />
            </IconButton>
          </Box>
          <CalculatorWidget />
        </Box>
      </Modal>
    </Box>
  );
}

export default function CalculatorModalButton() {
  return (
    <Fab
      size="large"
      aria-label="calculator"
      sx={{
        bgcolor: 'rgb(36, 87, 152, 0.8)',
        '&:hover': {
          bgcolor: 'rgb(36, 87, 152, 1)',
        },
        position: 'fixed',
        bottom: '2rem',
        right: '2rem',
        zIndex: '9999',
      }}
    >
      <CalculatorModal />
    </Fab>
  );
}
