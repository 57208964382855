import React from 'react';
import { Box, Container } from '@mui/material';

import MainAppBar from '../components/nav/MainAppBar';
import AppFooter from '../components/nav/AppFooter';
import CalculatorWidget from '../components/widgets/CalculatorWidget';

export default function CalculatorPage() {
  return (
    <Container disableGutters>
      <MainAppBar />
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        sx={{
          paddingX: { xs: 3, sm: 9, md: 25 },
          paddingTop: { xs: '2rem' },
          paddingBottom: { xs: '4rem' },
        }}
      >
        <CalculatorWidget />
      </Box>
      <AppFooter />
    </Container>
  );
}
