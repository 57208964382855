import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import MainAppBar from '../components/nav/MainAppBar';
import AppFooter from '../components/nav/AppFooter';

import RatesTabs from '../components/tabs/RatesTabs';
import CalculatorModalButton from '../components/modals/CalculatorModal';

export default function RatesPage() {
  return (
    <Container disableGutters>
      <MainAppBar />
      <Box sx={{ padding: { xs: '0rem', sm: '2rem' } }}>
        <RatesTabs />
      </Box>
      <AppFooter />
      <CalculatorModalButton />
    </Container>
  );
}
